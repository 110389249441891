// increment-views.js
import firebase from 'gatsby-plugin-firebase';

export const incrementViews = async (id) => {
  const ref = firebase.database().ref('/views').child(id);
  ref.transaction((views) => views + 1);
};

export const updateRating = async (id, newRating) => {
  const ref = firebase.database().ref('/rating').child(id);
  ref.transaction(() => newRating);
};

export const updateRusers = async (id) => {
  const ref = firebase.database().ref('/rusers').child(id);
  ref.transaction((rusers) => rusers + 1);
};