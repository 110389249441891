// ViewCounter.js
import React, { useEffect, useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { incrementViews } from '../../../lib/firebaseTxn';
import nFormatter from '../../../lib/nFormatter';

const ViewCounter = ({ slug, type }) => {
  const [viewCount, setViewCount] = useState('');
  const id = slug && slug.replace('/posts/', '');

  useEffect(() => {
    // 1 is displayed for a split second and then the correct count
    // This is a workaround
    const onViews = (newViews) => {
      const count = nFormatter(newViews.val() === 0 ? 1 : newViews.val(), 1);
      setViewCount(count);
    };

    if (type !== 'read') {
      incrementViews(id);
    }
    firebase.database().ref('/views').child(id).on('value', onViews);

    return () => {
      if (firebase.database()) {
        firebase.database().ref('/views').child(id).off('value', onViews);
      }
    };
  }, [id, type]);

  return (
    viewCount
  );
};

export default ViewCounter;